import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchTranslations } from '../../actions/translations';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'translationID', label: 'Translation ID', link: true },
  { id: 'translationName', label: 'Translation Name', link: true },
  { id: 'language', label: 'Language' },
  { id: 'fileUrl', label: 'File Url', decrypt: true },
  { id: 'imageUrl', label: 'Image Url', decrypt: true },
];

const Translations = (props) => {
  const { getTranslations } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>
                    Translations
                </Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/translations/new" color="success" size="sm">New</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable resource="translations" dataSource={getTranslations} columns={COLUMNS} {...props} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Translations.propTypes = {
  getTranslations: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  translations: PropTypes.arrayOf(PropTypes.object),
};

Translations.defaultProps = {
  translations: [],
};

const mapStateToProps = ({ translations }) => ({
  loading: translations.loading,
  translations: translations.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getTranslations: sort => fetchTranslations(sort),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Translations);
