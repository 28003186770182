import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchReciters } from '../../actions/reciters';
import DataTable from '../../components/data-table';

const COLUMNS = [
  { id: 'Guid', label: 'Guid', link: true },
  { id: 'TitleEN', label: 'Title En', link: true },
  { id: 'RecitorLabel', label: 'Reciter Label' },
  { id: 'timing', label: 'Timing' },
  { id: 'hidden', label: 'Hidden' },
  { id: 'featured', label: 'Featured' },
  { id: 'image_url', label: 'Image Url' },
  { id: 'order', label: 'Order' },
];

const Reciters = (props) => {
  const { getReciters } = props;
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg={4}>Reciters</Col>
                <Col lg={8} style={{ textAlign: 'right' }}>
                  <Button href="/#/reciters/new" color="success" size="sm">
                      New
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <DataTable resource="reciters" dataSource={getReciters} columns={COLUMNS} {...props} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Reciters.propTypes = {
  getReciters: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  reciters: PropTypes.arrayOf(PropTypes.object),
};

Reciters.defaultProps = {
  reciters: [],
};

const mapStateToProps = ({ reciters }) => ({
  loading: reciters.loading,
  reciters: reciters.items,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getReciters: sort => fetchReciters(sort),
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Reciters);
