import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cryptLib from '@skavinvarnan/cryptlib';

const Column = ({
  column, id, resource, record,
}) => {
  if (column.link) return <td><Link to={`${resource}/${record[id]}`}>{record[column.id].toString()}</Link></td>;
  if (column.decrypt) return <DecryptedColumn record={record} column={column} />;
  return <td>{record[column.id].toString()}</td>;
};
Column.propTypes = {
  column: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  record: PropTypes.oneOf(PropTypes.object).isRequired,
};

const DecryptedColumn = ({ column, record }) => {
  const decryptedString = cryptLib.decryptCipherTextWithRandomIV(record[column.id], 'hello_secret_key');
  return <td><a target="_blank" rel="noopener noreferrer" href={decryptedString}>{decryptedString}</a></td>;
};
DecryptedColumn.propTypes = {
  column: PropTypes.arrayOf(PropTypes.object).isRequired,
  record: PropTypes.oneOf(PropTypes.object).isRequired,
};

const List = ({ columns, data, resource }) => {
  const { id } = columns[0];
  return data.map(item => (
    <tr key={item[id]}>
      {columns.map(column => <Column resource={resource} record={item} id={id} column={column} />)}
    </tr>
  ));
};

List.propTypes = {
  reciter: PropTypes.oneOf(PropTypes.object),
};

List.defaultProps = {
  reciter: {},
};

export default List;
