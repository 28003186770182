// import { decamelizeKeys } from 'humps';

import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_RECITERS = 'REQUEST_RECITERS';
export const RECEIVE_RECITERS = 'RECEIVE_RECITERS';
export const RECEIVE_RECITER = 'RECEIVE_RECITER';

export const requestReciters = () => ({
  type: REQUEST_RECITERS,
});

export const receivedReciters = json => ({
  type: RECEIVE_RECITERS,
  json,
});

export const receivedReciter = json => ({
  type: RECEIVE_RECITER,
  json,
});

export const createReciter = params => (
  (dispatch) => {
    dispatch(requestReciters());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/reciters`, { method: 'POST', body: params })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Reciter or password incorrect'));
        }
        return json;
      });
  }
);

export const deleteReciter = id => (
  (dispatch) => {
    dispatch(requestReciters());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/reciters/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Reciter or password incorrect'));
        }
        return json;
      });
  }
);

export const fetchReciters = sort => (
  (dispatch) => {
    dispatch(requestReciters());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/all?order=${JSON.stringify(sort)}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedReciters([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedReciters([]));
        } else {
          dispatch(receivedReciters({ data: json.reciters } || []));
        }
        return json.reciters;
      });
  }
);

export const fetchReciter = guid => (
  (dispatch) => {
    dispatch(requestReciters());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/reciters/${guid}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedReciter(json));
        }
        return json;
      });
  }
);

export const saveReciter = (id, params) => (
  (dispatch) => {
    dispatch(requestReciters());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/reciters/${id}`, { method: 'POST', body: params })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Reciter or password incorrect'));
        }
        return json;
      });
  }
);
